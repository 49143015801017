<template>
  <div class="auth-content">
    <div
      class="back-button"
    >
      <back-button
        @click.native="()=>{ $router.push({path: '/login/phone', query: $route.query}) }"
      />
    </div>

    <v-row
      v-if="!selectMerchant"
      class="auth-content-box--confirm"
    >
      <v-col class="auth-content-box-wrapper">
        <div>
          <div class="header-box">
            <div style="display: flex;">
              <div
                class="header"
              >
                Введите код подтверждения
              </div>
            </div>
          </div>
          <v-row>
            <v-col class="body-m-regular neutral-700--text">
              Сейчас на ваш номер +{{ phone }} поступит входящий звонок, отвечать на него не нужно. Просто введите последние <b>4 цифры</b> входящего номера.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img src="@/assets/images/confirm-code-help.png" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form
                ref="form"
                class="auth-form"
                @submit.prevent="confirm()"
              >
                <div style="display: flex; justify-content: center;">
                  <v-otp-input
                      length="4"
                      type="number"
                      v-model="code"
                  ></v-otp-input>
                </div>
                <div style="margin-top: 25px; text-align: center;">
                  Код действителен еще {{ timerStr }}
                </div>
                <div
                  class="auth-form-action"
                  style="margin-top: 34px;"
                >
                  <v-btn
                    color="primary"
                    style="width: 100%;"
                    :loading="loading"
                    :disabled="!valid || codeExpire"
                    @click="confirm()"
                  >
                    <span
                      class="iconify"
                      style="margin-right: 8px;"
                      data-icon="ion:log-out-outline"
                      data-inline="false"
                    />
                    Войти в аккаунт
                  </v-btn>
                </div>
                <div
                  class="auth-form-forgot-password"
                  @click="recaptchaExecute()"
                >
                  Отправить код еще раз
                </div>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <select-merchant
      v-else
      :merchants="merchants"
      @select="login"
    />
    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="$config.app.RECAPTCHA_SITE_KEY"
      :load-recaptcha-script="true"
      @verify="sendSMSCode"
      @expired="onCaptchaExpired"
    />
  </div>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import { mapGetters } from 'vuex'
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    components: {
      BackButton: () => import('@/views/auth/components/BackButton'),
      SelectMerchant: () => import('@/views/auth/components/SelectMerchant'),
      VueRecaptcha,
    },
    directives: { mask },
    props: {
      phone: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        code: null,
        visible1: false,
        loading: false,
        selectMerchant: false,
        codeExpire: false,
        timerStr: '00:00',
        timerTTL: null,
        timerId: null,
      }
    },
    computed: {
      ...mapGetters('auth/auth', [
        'merchants',
        'merchant',
        'device',
      ]),
      ...mapGetters('auth/phone', [
        'loginId',
        'expiredAt',
        'codeTTL',
      ]),
      valid () {
        if (this.code && this.code.length === 4) return true
        return false
      },
    },
    mounted () {
      this.$store.dispatch('auth/auth/InitDevice')
      this.clearCode()
      this.startTimer()
      if (!this.phone || !this.loginId) {
        this.$router.push({
          path: '/login/phone',
        })
      }
    },
    destroyed () {
      this.stopTimer()
    },
    methods: {
      onCaptchaExpired () {
        this.$refs.recaptcha.reset()
      },
      recaptchaExecute () {
        this.$refs.recaptcha.execute()
      },
      stopTimer () {
        if (this.timerId) clearInterval(this.timerId)
      },
      startTimer () {
        this.stopTimer()
        console.log(this.codeTTL)
        if (!this.codeTTL) return
        this.timerTTL = this.codeTTL
        //
        this.timerId = setInterval(() => {
          this.timerTTL--
          if (this.timerTTL >= 0) {
            this.timerStr = this.$moment.unix(this.timerTTL).format('mm:ss')
            this.codeExpire = false
          } else {
            this.stopTimer()
            this.codeExpire = true
            this.timerStr = '00:00'
          }
        }, 1000)
      },
      clearCode () {
        this.code = null;
      },
      async sendSMSCode (recaptchaToken) {
        this.clearCode()
        const user = {
          phone: this.phone,
          device_id: this.device.id,
          device_token: this.device.token,
          device_type: this.device.type,
          recaptcha_token: recaptchaToken,
        }
        console.log(user)
        try {
          this.loading = true
          await this.$store.dispatch('auth/phone/login', user)
          this.startTimer()
        } catch (e) {
          this.$router.push({
            path: '/login/phone',
          })
        } finally {
          this.loading = false
        }
      },
      toRoute (path) {
        if (this.$route.path !== path) this.$router.push(path)
      },
      clearPhoneMask (p) {
        if (p) {
          p = String(p).match(/\d/g)
          if (p) p = p.join('')
        }
        return p
      },
      async confirm () {
        console.log('<confirm>')
        if (!this.valid) return

        const login = {
          id: this.loginId,
          code: this.code,
          device_id: this.device.id,
          device_token: this.device.token,
          device_type: this.device.type,
        }
        console.log(login)
        try {
          this.loading = true
          await this.$store.dispatch('auth/phone/confirm', login)
          // выбор мерчанта для логина или сразу логин
          if (this.merchants.length > 1) {
            this.selectMerchant = true
          } else {
            this.$router.push({ path: this.$route.query.redirect || '/' })
          }
        } catch (e) {
          this.clearCode()
        } finally {
          this.loading = false
        }
      },
      async login (merchId = null) {
        const login = {
          id: merchId,
          access_token: this.$session.get('access_token'),
        }
        console.log(login)
        try {
          this.loading = true
          await this.$store.dispatch('auth/merchant/login', login)
          // выбор мерчанта для логина или сразу логин
          this.$router.push({ path: this.$route.query.redirect || '/' })
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
@import "~@/styles/auth"
</style>
